<template>
    <div id="maisuni-home-vue" class="maisuni">
        <div class="header pos-fixed pos-top pos-left pos-right">
            <div class="content wrapper-header">
                <div>
                    <img
                        class="lazy img-responsive"
                        :src="`${$scope.CDN_IMAGES}/images/site/mais-uni-logo.png?auto=compress,format&q=65&w=197&h=33`"
                        alt="Mais Uni"
                    />
                </div>
                <div>
                    <ul class="menu-nav hidden-md-down">
                        <li class="text-bold" v-for="(links, index) in headerLinks" :key="index">
                            <a class="cursor-pointer" @click="scrollTo(links.ref)">{{
                                links.label
                            }}</a>
                        </li>
                    </ul>
                    <i
                        class="icon-cr icon-cr-hover icon-chevron-left hidden-md-up"
                        @click="openMenu()"
                    ></i>

                    <ul v-if="menuOpen" class="menu-mobile">
                        <li class="text-bold" v-for="(links, index) in headerLinks" :key="index">
                            <a class="cursor-pointer" @click="scrollTo(links.ref)">{{
                                links.label
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="ft-sec">
            <div class="content" ref="quemsomos">
                <h1 class="text-center text-bold">Ei! Somos a Mais Uni ;)</h1>
                <h3 class="text-center">
                    Facilitamos a vida de escolas, pais e alunos na compra de uniformes
                </h3>
            </div>
        </div>

        <div class="bg-slide mt-7 pb-5 pt-3">
            <div class="content">
                <h2 class="text-center text-bold h2">Acesse a coleção de sua escola:</h2>
                <Slide />
            </div>
        </div>

        <div class="content mt-5">
            <div class="text-center video-player">
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/NuugReOFME0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                >
                </iframe>
            </div>
        </div>

        <div class="space" ref="como_funciona">
            <div class="content">
                <h2 class="text-center text-bold">Como Funciona?</h2>
                <div
                    class="flex-grid grid-cols-2 md:grid-cols-4 col-gap-8 text-classic-sans text-center flex-justify-center"
                >
                    <div
                        v-for="(item, index) in Cards"
                        class="flex-lg-2 flex-md-6 flex-sm-6 one-card text-center"
                    >
                        <img class="img-card mt-4" :src="item.link" alt="Card image cap" />
                        <div class="body-card">
                            <h5 class="text-bold">{{ item.text }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="space mb-4" ref="escolas">
            <h1 class="text-center text-bold">Escolas</h1>
            <div class="carousel">
                <div class="content">
                    <div class="flex-grid flex-align-center">
                        <div class="flex-md-6 text-center">
                            <img :src="carousel" class="img-escola" />
                        </div>
                        <div class="flex-md-6 text-center">
                            <h3 class="text-bold">
                                Unificamos toda a venda e distribuição de uniformes da Escola Hub e
                                todas as suas unidades espalhadas pelo Brasil
                            </h3>
                            <div class="d-flex flex-justify-center flex-spaced-fixed">
                                <div class="flex-item-align-center">
                                    <img class="img-icon" :src="icon" />
                                </div>
                                <div>
                                    <h3 class="text-bold mb-4 ml-2">maisuni.com/escolahub</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="space mt-7" ref="contato">
            <h1 class="text-center text-bold h2">
                Cadastre a sua escola e faça parte da primeira plataforma de uniformes escolares do
                Brasil:
            </h1>
            <div class="form">
                <div class="content">
                    <form>
                        <div class="flex-grid flex-align-center">
                            <div class="form-group flex-md-6">
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Nome:"
                                    v-model="form.name"
                                />
                            </div>
                            <div class="form-group flex-md-6">
                                <input
                                    class="form-input"
                                    type="email"
                                    placeholder="Email:"
                                    v-model="form.email"
                                />
                            </div>
                        </div>
                        <div class="flex-grid flex-align-center">
                            <div class="form-group flex-md-6">
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Telefone:"
                                    v-model="form.phone"
                                />
                            </div>
                            <div class="form-group flex-md-6">
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Escola:"
                                    v-model="form.school"
                                />
                            </div>
                        </div>

                        <div class="flex-grid flex-align-center">
                            <div class="form-group flex-md-6">
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Cargo ocupado na escola:"
                                    v-model="form.office"
                                />
                            </div>
                            <div class="form-group flex-md-6">
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Quantidade de alunos:"
                                    v-model="form.students"
                                />
                            </div>
                        </div>
                        <div class="text-center">
                            <Alert :alert="errorAlert" />
                            <input
                                type="button"
                                value="Enviar"
                                class="btn btn-light btn-width"
                                @click="submit"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Alert from '../../common/Alert';
    import Slide from '../../common/Slide';
    import { maisUniService } from '../../../service/resource';

    export default {
        name: 'Home',

        components: {
            Alert,
            Slide,
        },

        data() {
            return {
                Cards: [
                    {
                        //${$scope.CDN_IMAGES}/images/site/mais-uni-logo.png?auto=compress,format&q=65&w=197&h=33`"
                        link:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/cadastramos-sua-escola.png?auto=compress,format&q=65&w=155&h=155',
                        text: 'Cadastramos a sua Escola',
                    },
                    {
                        link:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/criamos-uniformes.png?auto=compress,format&q=65&w=155&h=155',
                        text: 'Criamos, juntos, a coleção de uniformes',
                    },
                    {
                        link:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/criamos-paginas.png?auto=compress,format&q=65&w=155&h=155',
                        text: 'Criamos a página com todos os produtos: camisetas, calças, shorts, blusas e um tanto mais de opções!',
                    },
                    {
                        link:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/pagina-exclusiva-da-escola.png?auto=compress,format&q=65&w=155&h=155',
                        text: 'Os pais e alunos compram em sua página exclusiva',
                    },
                    {
                        link:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/cuidamos-de-tudo.png?auto=compress,format&q=65&w=155&h=155',
                        text: 'Cuidamos de tudo! Envio até a casa do aluno e todo o atendimento necessário :)',
                    },
                ],
                carousel:
                    this.$scope.CDN_IMAGES +
                    '/images/site/home/escola-hub.png?auto=compress,format&q=65&w=494&h=393',
                icon:
                    this.$scope.CDN_IMAGES +
                    '/images/site/home/icon-www.png?auto=compress,format&q=65&w=42&h=43',
                headerLinks: [
                    { label: 'Quem somos', ref: 'quemsomos' },
                    { label: 'Como Funciona', ref: 'como_funciona' },
                    { label: 'Escolas', ref: 'escolas' },
                    { label: 'Contato', ref: 'contato' },
                ],
                menuOpen: false,
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    school: '',
                    office: '',
                    students: '',
                },
                errorAlert: {
                    message: null,
                    isError: true,
                },
                submitting: false,
                submitted: false,
            };
        },

        methods: {
            scrollTo(ref) {
                cr$.byEl(this.$refs[ref]).scrollIntoView();
            },

            openMenu() {
                this.menuOpen = !this.menuOpen;
            },
            submit() {
                if (this.validate()) {
                    this.sendEmail();
                } else {
                    this.showError('Preencha todos os campos');
                }
            },
            validate() {
                return this.validateForm();
            },
            validateForm() {
                return (
                    this.validateText(this.form.name) &&
                    this.validateText(this.form.email) &&
                    this.validateText(this.form.phone) &&
                    this.validateText(this.form.school) &&
                    this.validateText(this.form.office) &&
                    this.validateText(this.form.students)
                );
            },
            validateText(text) {
                return text != null && text.trim().length > 0;
            },
            showSuccess(message, persist) {
                if (persist) {
                    this.errorAlert = {
                        message,
                        isError: false,
                        timeout: 0,
                    };
                } else {
                    this.errorAlert = {
                        message,
                        isError: false,
                    };
                }
            },
            showError(message) {
                this.errorAlert = {
                    message,
                    isError: true,
                };
            },
            sendEmail() {
                this.submitting = true;
                maisUniService
                    .sendEmail(this.form)
                    .then(() => {
                        this.showSuccess(
                            'Seus dados foram enviados com sucesso e entraremos em contato em breve :)',
                            true
                        );
                        this.submitted = true;
                    })
                    .catch(() =>
                        this.showError(
                            'Não foi possível enviar as informações. Por favor, tente novamente.'
                        )
                    )
                    .then(() => (this.submitting = false));
            },
        },
    };
</script>