<template>
    <div class="slide-container d-flex flex-align-center flex-spaced-fixed">
        <img
            style="cursor: pointer"
            :src="`${$scope.CDN_IMAGES}/images/site/home/escolas/setae.png?auto=compress,format&q=100`"
            @click="moveSlide('prev')"
            alt="Seta Esquerda"
            class="img-responsive arrow-slide"
        />
        <div class="slide-container" :style="slidePosition">
            <div class="slide-item" v-for="(slide, index) in visibleSlides" :key="index">
                <a :href="slide.href">
                    <img :src="slide.image" :alt="slide.alt" class="img-responsive" />
                </a>
            </div>
        </div>
        <img
            style="cursor: pointer"
            :src="`${$scope.CDN_IMAGES}/images/site/home/escolas/setad.png?auto=compress,format&q=100`"
            @click="moveSlide('next')"
            alt="Seta Direita"
            class="img-responsive arrow-slide"
        />
    </div>
</template>

<script>
    export default {
        name: 'Slide',

        data() {
            return {
                currentIndex: 0,
                slides: [
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/apogeu.png?auto=compress,format&q=100',
                        id: 1,
                        href: '/apogeu/',
                        alt: 'Apogeu',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/elite.png?auto=compress,format&q=100',
                        id: 2,
                        href: '/elite/',
                        alt: 'Escola Elite',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/escola-hub.png?auto=compress,format&q=100',
                        id: 3,
                        href: '/escolahub/',
                        alt: 'Escola Hub',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/giramundo.png?auto=compress,format&q=100',
                        id: 4,
                        href: '/giramundo/',
                        alt: 'Giramundo',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/integra.jpeg?auto=compress,format&q=100',
                        id: 5,
                        href: '/integra/',
                        alt: 'Escola Integra',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/pense.jpeg?auto=compress,format&q=100',
                        id: 6,
                        href: '/pensi/',
                        alt: 'Pensi',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/machadosobrinhohome.png?auto=compress,format&q=100',
                        id: 7,
                        href: '/machadosobrinho/',
                        alt: 'Machado Sobrinho',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/apogeu.png?auto=compress,format&q=100',
                        id: 8,
                        href: '/apogeu/',
                        alt: 'Apogeu',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/elite.png?auto=compress,format&q=100',
                        id: 9,
                        href: '/elite/',
                        alt: 'Escola Elite',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/escola-hub.png?auto=compress,format&q=100',
                        id: 10,
                        href: '/escolahub/',
                        alt: 'Escola Hub',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/giramundo.png?auto=compress,format&q=100',
                        id: 11,
                        href: '/giramundo/',
                        alt: 'Giramundo',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/integra.jpeg?auto=compress,format&q=100',
                        id: 12,
                        href: '/integra/',
                        alt: 'Escola Integra',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/pense.jpeg?auto=compress,format&q=100',
                        id: 13,
                        href: '/pensi/',
                        alt: 'Pensi',
                    },
                    {
                        image:
                            this.$scope.CDN_IMAGES +
                            '/images/site/home/escolas/machadosobrinhohome.png?auto=compress,format&q=100',
                        id: 14,
                        href: '/machadosobrinho/',
                        alt: 'Machado Sobrinho',
                    },
                ],
            };
        },

        computed: {
            slidePosition() {
                return {
                    left: `${this.currentIndex * -25}%`,
                };
            },
            visibleSlides() {
                const visibleSlides = [];
                const maxSlides = this.currentIndex + 4;
                for (let i = this.currentIndex; i < maxSlides; i++) {
                    const slide = this.slides[i];
                    if (slide) {
                        visibleSlides.push(slide);
                    }
                }
                return visibleSlides;
            },
        },

        methods: {
            moveSlide(content) {
                if (content == 'next') {
                    this.currentIndex++;
                    if (this.currentIndex >= this.slides.length - 3) {
                        this.currentIndex = 0;
                    }
                } else {
                    this.currentIndex--;
                    if (this.currentIndex < 0) {
                        this.currentIndex = this.slides.length - 4;
                    }
                }
            },
            show(content) {
                if (content == 'next') {
                    this.currentIndex++;
                } else {
                    this.currentIndex--;
                }
            },
        },
    };
</script>

<style></style>
